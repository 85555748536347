import { state } from ":mods";
import { ToastViewProps } from "../model";
import { Show, Switch, onCleanup, onMount, splitProps } from "solid-js";
import { animate, spring } from "motion";
import { Spinner } from "../../animations/views";
import "../style/toast.css";

export function Toast(props: ToastViewProps) {
  const [local, others] = splitProps(props, ["message", "type", "as_loader", "no_timer", "onUnmount"]);
  // console.log("from alert :: ", message.length, message.length * 173);
  const timeout = !local.message ? 300 : local.message.length * 70;
  const $unmount = state.create(false);
  const $as_loader = state.createMemo(() => local.as_loader);

  let ref: HTMLDivElement = undefined;
  function remove() {
    const width = ref.clientWidth;
    animate(
      ref as any,
      {
        // TODO: convert to const keys
        x: [0, -60, width * 2],
      },
      {
        duration: 0.8,
        easing: "ease-in-out",
      }
    ).finished.then(() => {
      // console.log("removing alert toast :: ");
      if (local.onUnmount) {
        local.onUnmount();
      }
      $unmount.set(true);
    });
  }
  onMount(() => {
    animate(
      ref as any,
      {
        // TODO: convert to const keys
        y: [100, 0],
      },
      {
        duration: 0.8,
        easing: spring({
          // restSpeed: 100,
        }),
      }
    ).finished.then(() => {
      setTimeout(() => {
        if (!$as_loader()) {
          remove();
        }
      }, timeout);
    });
  });

  // console.log("toast type :: ", local.type);
  return (
    <Show when={!$unmount.value} fallback={null}>
      <div {...others} ref={ref} class={`toast ${others.class ?? ""}`}>
        <div class="banner" classList={{ [local.type]: true }} />
        <div class="content-container">
          {/* <span className="text-2xl capitalize text-white">{notification.type}</span> */}
          <span class="message">{local.message}</span>
          <button
            onClick={() => remove()}
            // style={{ position: "absolute" }}
            // disabled={_state !== "show"}
            class="button"
          >
            <Show when={!$as_loader()} fallback={<Spinner class="flex$ text-1rem extend$" />}>
              Dismiss
            </Show>
          </button>
        </div>
      </div>
    </Show>
  );
}
